import {
  ContentItemModels,
  ContentTypeModels,
  LanguageVariantModels,
  ManagementClient,
  SharedModels,
} from "@kontent-ai/management-sdk";
import OpenAI from "openai";
import { ASSISTANT_ID, VECTOR_STORE_IDS } from "../constants/openai";

type CopyRecursivelyProps = {
  client: ManagementClient;
  openAiClient?: OpenAI | null;
  currentLang: string;
  requestedLangs: string[];
  itemId: string;
  createNewVersionForExistingItems: boolean;
  translateItems?: boolean;
  translatorInstructions?: string;
  isPreview: boolean;
};

type CopyRecursivelyPropsResponse = {
  existingItems: ExistingOrToCreateItem[];
  contentItemsToCreate: ExistingOrToCreateItem[];
  createdLanguageVariants: LanguageVariantModels.ContentItemLanguageVariant[];
};

export type ExistingOrToCreateItem = ContentItemModels.ContentItem & {
  language: string;
};

export class ManageKontentItem {
  private contentTypes: ContentTypeModels.ContentType[] = [];
  private existingContentItems: ExistingOrToCreateItem[];
  private contentItemsToCreate: ExistingOrToCreateItem[];
  private createdLanguageVariants: LanguageVariantModels.ContentItemLanguageVariant[];

  public constructor() {
    this.existingContentItems = [];
    this.createdLanguageVariants = [];
    this.contentItemsToCreate = [];
  }

  async copyRecursively({
    client,
    openAiClient,
    currentLang,
    requestedLangs,
    itemId,
    createNewVersionForExistingItems,
    isPreview,
    translateItems = false,
    translatorInstructions = "",
  }: CopyRecursivelyProps): Promise<CopyRecursivelyPropsResponse> {
    //
    // allAvailableLanguages - get all languages available in Kentico
    // publishedWorkflowStepId - get the id of the workflow step "Published"
    //
    const allAvailableLanguages = await client.listLanguages().toPromise();
    const publishedWorkflowStepId = await this.getIdOfPublishedWorkflowStep(
      client
    );

    try {
      //
      // Get source data
      // sourceItemData - get content item data
      // sourceItemMetaData - get content item metadata
      //
      const sourceItemData = (
        await client
          .viewLanguageVariant()
          .byItemId(itemId)
          .byLanguageCodename(currentLang)
          .toPromise()
      ).data;

      const sourceItemMetaData = (
        await client.viewContentItem().byItemId(itemId).toPromise()
      ).data;

      //
      // Start copying the data into every requested language
      //
      for (const language of requestedLangs) {
        // get the Kentico id of the target language
        const targetLanguage = allAvailableLanguages.data.items.find(
          (m) => m.codename === language
        );
        if (!targetLanguage) {
          throw new Error(`Invalid language with codename '${language}'`);
        }

        //
        // Check if the item already exists in target languages
        // If it does, and overwriteExistingVariants is false, skip this language
        //
        const languageVariants = await client
          .listLanguageVariantsOfItem()
          .byItemId(itemId)
          .toPromise();

        const existingTargetItem = languageVariants.data.items.find(
          (m) => m.language.id === targetLanguage.id
        );

        if (existingTargetItem) {
          // item already exists in given language
          this.existingContentItems.push({ ...sourceItemMetaData, language });
        }

        let createVariant = false;

        if (createNewVersionForExistingItems) {
          createVariant = true;
        } else {
          if (!existingTargetItem) {
            createVariant = true;
          }
        }

        if (createVariant) {
          this.contentItemsToCreate.push({ ...sourceItemMetaData, language });
        }

        if (!isPreview && createVariant) {
          //
          // Create new version of variant if item already exists
          //
          if (
            existingTargetItem &&
            existingTargetItem.workflow.stepIdentifier === publishedWorkflowStepId
          ) {
            await client
              .createNewVersionOfLanguageVariant()
              .byItemId(itemId)
              .byLanguageCodename(language)
              .toPromise();
          }

          //
          // Add data to newly created version
          //

          let elementsToCreate = sourceItemData.elements;

          if (translateItems && openAiClient) {
            elementsToCreate = await Promise.all(
              sourceItemData.elements.map(async (element) => {
                if (typeof element.value === "string" && element.value !== "") {
                  const sourceLanguageCode = currentLang
                    .split("_")[0]
                    .toUpperCase();
                  const targetLanguageCode = targetLanguage.codename
                    .split("_")[0]
                    .toUpperCase();

                  let translation = "";

                  const stream = await openAiClient.beta.threads.createAndRun({
                    assistant_id: ASSISTANT_ID,
                    stream: true,
                    thread: {
                      messages: [
                        {
                          role: "assistant",
                          content: translatorInstructions,
                        },
                        {
                          role: "user",
                          content: `Translate from ${sourceLanguageCode} to ${targetLanguageCode}. Original text: ${element.value}`,
                        },
                      ],
                      tool_resources: {
                        file_search: {
                          vector_store_ids: VECTOR_STORE_IDS,
                        },
                      },
                    },
                  });

                  for await (const event of stream) {
                    if (event.event === "thread.run.completed") {
                      const runId = event.data.id;
                      const threadId = event.data.thread_id;
                      const messages =
                        await openAiClient.beta.threads.messages.list(threadId);

                      const responseMessage = messages.data.find((message) => {
                        return message?.run_id === runId;
                      });

                      const firstTranslationNode = responseMessage?.content[0];

                      if (firstTranslationNode?.type === "text") {
                        translation = firstTranslationNode.text.value;
                      }
                      await openAiClient.beta.threads.del(threadId);
                    }
                  }

                  return { ...element, value: translation };
                }
                return element;
              })
            );
          }

          const targetVariant = await client
            .upsertLanguageVariant()
            .byItemId(itemId)
            .byLanguageCodename(language)
            .withData(() => {
              return { elements: elementsToCreate } as any;
            })
            .toPromise();

          this.createdLanguageVariants.push(targetVariant.data);
        }

        const contentType = await this.getOrSetContentType(
          client,
          sourceItemMetaData.type.id
        );

        for (const element of sourceItemData.elements) {
          const contentTypeElement = contentType.elements.find(
            (m: any) => m.id === element.element.id
          );

          if (
            contentTypeElement &&
            contentTypeElement.type === "modular_content"
          ) {
            const linkedItemReferences =
              element.value as SharedModels.IReferenceObject[];
            for (const linkedItemReference of linkedItemReferences) {
              const linkedItemId = linkedItemReference.id;

              if (linkedItemId) {
                await this.copyRecursively({
                  client,
                  openAiClient,
                  currentLang,
                  requestedLangs: [language],
                  itemId: linkedItemId,
                  createNewVersionForExistingItems,
                  translateItems,
                  translatorInstructions,
                  isPreview,
                });
              }
            }
          }
        }
      }
    } catch (error: any) {
      console.error(`Error ${error?.code}. ${error?.message}`);
    }

    return {
      existingItems: this.existingContentItems,
      createdLanguageVariants: this.createdLanguageVariants,
      contentItemsToCreate: this.contentItemsToCreate,
    };
  }

  async getOrSetContentType(
    client: ManagementClient,
    contentItemId: string
  ): Promise<ContentTypeModels.ContentType> {
    const existingContentType = this.contentTypes.find(
      (m) => m.id === contentItemId
    );

    if (existingContentType) {
      return existingContentType;
    }

    const contentType = (
      await client.viewContentType().byTypeId(contentItemId).toPromise()
    ).data;

    this.contentTypes.push(contentType);

    return contentType;
  }

  async getIdOfPublishedWorkflowStep(
    client: ManagementClient
  ): Promise<string | undefined> {
    const workflowSteps = (await client.listWorkflows().toPromise()).data;
    return workflowSteps.find((m) => m.codename === "published")?.id;
  }
}
