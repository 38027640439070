import { ManagementClient } from "@kontent-ai/management-sdk";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { getLanguages, Language } from "../lib/get-languages";
import { getManagementClient } from "../lib/get-management-client";
import { initOpenAi } from "../lib/open-ai-client";
import OpenAI from "openai";

declare const CustomElement: any;

type CurrentItem = {
  codename: string;
  id: string;
  name: string;
};

const ClientContext = React.createContext<{
  status: "enabled" | "disabled";
  client: ManagementClient | null;
  openAiClient: OpenAI | null;
  languages: Language[];
  currentLang: string | null;
  currentItem: CurrentItem | null;
  translatorInstructions: string;
}>({
  status: "disabled",
  client: null,
  openAiClient: null,
  languages: [],
  currentLang: null,
  currentItem: null,
  translatorInstructions: "",
});

export const ClientProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [status, setStatus] = useState<"enabled" | "disabled">("enabled");
  const [client, setClient] = useState<ManagementClient | null>(null);
  const [openAiClient, setOpenAiClient] = useState<OpenAI | null>(null);
  const [currentLang, setCurrentLang] = useState<string | null>(null);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [currentItem, setCurrentItem] = useState<CurrentItem | null>(null);
  const [translatorInstructions, setTranslatorInstructions] = useState("");

  useEffect(() => {
    initCustomElement();
  }, []);

  return (
    <ClientContext.Provider
      value={{
        status,
        client,
        openAiClient,
        languages,
        currentLang,
        currentItem,
        translatorInstructions,
      }}
    >
      {children}
    </ClientContext.Provider>
  );

  async function initCustomElement() {
    try {
      CustomElement.init(async (element: any, context: any) => {
        const managementClient = getManagementClient(
          element.config.projectId,
          element.config.apiKey
        );
        if (managementClient) {
          const avaliableLanguages = await getLanguages(
            managementClient,
            context.variant.codename
          );
          const openAiClient = initOpenAi(element.config.assistantApiKey);
          CustomElement.setHeight(400);
          setCurrentItem(context.item);
          setCurrentLang(context.variant.codename);
          setLanguages(avaliableLanguages);
          setClient(managementClient);
          setOpenAiClient(openAiClient);
          setTranslatorInstructions(element.config.translatorInstructions);
        }
        CustomElement.onDisabledChanged((disabled: boolean) => {
          if (disabled) {
            setStatus("disabled");
          } else {
            setStatus("enabled");
          }
        });
      });
    } catch (err) {
      console.error(err);
    }
  }
};

export const useClientContext = () => useContext(ClientContext);
